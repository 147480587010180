<template>
  <b-table
    borderless
    striped
    responsive
    hover
    large
    v-if="tableFields"
    :thead-class="tableHeader"
    :fields="tableFields"
    :items="tableItems"
  >
    <template #head()="data">
      <p :id="`tooltip-target-${data.label}`" class="p-2">
        <span>{{ $t(data.label) }}</span>
        <InfoIcon v-if="data.field.hover" class="info-icon" />
        <b-tooltip
          v-if="getTitle(data.field)"
          :target="`tooltip-target-${data.label}`"
          triggers="hover"
          placement="left"
          boundary="viewport"
        >
          <div class="response">
            <span>{{ getTitle(data.field) }}</span>
            <span
              v-if="data.field.correct && data.field.correct.length > 0"
              class="font-weight-bold w-color-dark-green"
            >
              {{
                data.field.correct.length > 1
                  ? "Správne odpoveďe:"
                  : "Správna odpoveď:"
              }}
            </span>
            <ul
              v-if="data.field.correct && data.field.correct.length > 0"
              class="multiple-choice"
            >
              <li v-for="(answer, index) of data.field.correct" :key="index">
                {{ answer }}
              </li>
            </ul>
          </div>
        </b-tooltip>
      </p>
    </template>
    <template #cell(index)="data">
      {{ data.index + 1 }}
    </template>
    <template #cell(name)="row">
      <p class="p2">
        <b>
          {{ getUserDisplayName(row.item) }}
        </b>
      </p>
    </template>
    <template #cell(exams_count)="row">
      <p class="p-2">
        <b>
          {{ getTaskPoints(row.item) }}
        </b>
        {{ getPointsPercentage(row.item) }}
      </p>
    </template>
    <template #cell(submitted_at)="row">
      <span class="mr-1">
        {{ formatDate(getResponseCreatedAt(row.item)) }}
      </span>
      <span class="ml-1">
        {{ formatTime(getResponseCreatedAt(row.item)) }}
      </span>
    </template>
    <template
      v-for="slot in tableFields.length"
      v-slot:[toQuestionCellName(slot)]="data"
    >
      <span
        :id="`tooltip-response-target-${data.index}-${slot}`"
        :title="data.value.response"
        :key="slot"
      >
        <b-tooltip
          v-if="data.value.response"
          :target="`tooltip-response-target-${data.index}-${slot}`"
          triggers="hover"
          placement="left"
          boundary="viewport"
        >
          <ul
            v-if="isNonemptyArray(data.value.response)"
            class="multiple-choice"
          >
            <li v-for="(response, index) of data.value.response" :key="index">
              {{ response }}
            </li>
          </ul>
          <span v-else-if="data.value.response.length > 0">
            {{ data.value.response }}
          </span>
        </b-tooltip>
        <GreenCheckmarkIcon v-if="data.value.isCorrect" />
        <RedCrossIcon v-else />
      </span>
    </template>
  </b-table>
</template>
<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import { get } from "lodash";
import { getPoints, getUserDisplayName } from "/utils/helpers";
import { sortByStringProperty } from "/utils/shared.ts";
import InfoIcon from "/assets/icons/infoIcon.svg?inline";
import GreenCheckmarkIcon from "/assets/icons/checkmarkGreen.svg?inline";
import RedCrossIcon from "/assets/icons/crossRed.svg?inline";

export default {
  components: {
    InfoIcon,
    GreenCheckmarkIcon,
    RedCrossIcon,
  },

  props: {
    task: {
      required: true,
      type: Object,
    },
    tableHeader: {
      required: false,
      type: String,
      default: "",
    },
  },

  created() {
    this._fillTable();
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
  },

  methods: {
    isNonemptyArray(array) {
      return Array.isArray(array) && array.length > 0;
    },

    toQuestionCellName(slot) {
      return `cell(question${slot})`;
    },

    getTitle(field) {
      if (typeof get(field, "hover") === "string") {
        return field.hover;
      }

      return get(field, "hover.title");
    },

    formatDate(date) {
      return moment(date).utc().tz("Europe/Bratislava").format("DD.MM.YYYY");
    },

    formatTime(time) {
      return moment(time).utc().tz("Europe/Bratislava").format("HH:mm");
    },

    getUserDisplayName,
    getResponseCreatedAt(response) {
      return get(response, "attributes.createdAt");
    },

    getPointsPercentage(item) {
      const { correctPoints, maxPoints } = getPoints({
        response: item,
        course: this.course,
      });
      return ` (${Math.round((correctPoints / maxPoints) * 100)}%)`;
    },

    getTaskPoints(item) {
      const { correctPoints, maxPoints } = getPoints({
        response: item,
        course: this.course,
      });
      return `${correctPoints} / ${maxPoints}`;
    },

    _fillTable() {
      // ---> Generate field names for the table

      // info fields
      this.tableFields = [
        { key: "index", label: "#" },
        { key: "name", label: "name", span: 10 },
        { key: "exams_count", label: "points-count" },
        { key: "submitted_at", label: "submitted-at" },
      ];

      // quiz question fields
      this.course = get(this.task, "attributes.course");
      this.quiz = get(this.course, "data.attributes.quiz.data.attributes");
      this.quiz.questions.forEach((question, i) => {
        if (question.points === 0) {
          return;
        }

        const correct = question.answers
          .filter((answer) => answer.isCorrect)
          .map((answer) => answer.content);

        const questionColumn = {
          key: `question${i + 1}`,
          label: `${i + 1}`,
          hover: question,
          correct,
        };

        this.tableFields.push(questionColumn);
      });

      //  ---> Generate row data for the table
      this.tableItems = [
        ...(get(this.task, "attributes.responses.data") || []),
      ];

      Object.values(this.tableItems).forEach((entry) => {
        const performance =
          get(
            entry,
            "attributes.quizResponse.data.attributes.responses.payload"
          ) || [];

        performance.forEach((answer, i) => {
          entry["question" + (i + 1)] = answer;
        });
      });

      this.tableItems = sortByStringProperty(
        this.tableItems,
        "attributes.groupMember.data.attributes.user.data.attributes.lastName"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
svg,
span {
  cursor: pointer;
}
.info-icon {
  margin: 0 0 5px 5px;
}

.response {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.multiple-choice {
  list-style: inside none;
  padding: 0;
  margin: 0;
}

::v-deep table {
  overflow-y: hidden;
  overflow-x: hidden;
  thead {
    tr {
      border-bottom: 1px solid #cadeea;
      th {
        text-align: center;
        padding: 10px 0;
        span {
          color: #86b2cd;
          font-weight: lighter;
          font-family: Poppins;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0;
        text-align: center;
        align-items: center;
        color: #2b2e4a;
        vertical-align: middle;

        p {
          margin-bottom: 0;
        }
        @media screen and (max-width: 600px) {
          font-size: 10px;

          p {
            font-size: 10px;
          }
        }

        button {
          color: #86b2cd !important;
          border-color: #86b2cd !important;
          font-size: small;
          font-weight: bold;
          min-width: 136px;
          min-height: 39px;
          padding: 0;

          &:hover {
            background-color: #fb2564;
            color: #ffffff !important;
            border-color: #fb2564 !important;
          }
        }
        a {
          text-decoration: none;
          color: #2b2e4a;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
